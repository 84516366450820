import { useEffect } from "react";

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <section id="page-title" className="page-title-parallax page-title-dark include-header" style={{backgroundColor:"#ffffff",backgroundImage:"url('assets/images/product/background.png')",backgroundSize:"contain",paddingBottom:"0"}}>

                <div className="container clearfix topmargin-lg bottommargin-lg">
                    <div className="col-lg-6">
                        <h1 className="page-header">Privacy Policy</h1>
                        <p className="lead"></p>
                    </div>
                </div>

            </section>
            <section id="content">
                <div className="content-wrap">
                    <div className="container">

                        <div className="single-post mb-0">

                            <div className="entry">

                                <div className="entry-title">
                                    <h3>How truclimate processes your personal data/company information</h3>
                                </div>

                                <div className="entry-title">
                                    <p className="sub-header mb-0">Introduction</p>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>PT Truclimate Dekarbonisasi Indonesia (“TruClimate”), respects your rights to privacy protection regarding the processing, collection, use, disclosure, and handling of personal data/company information. This Privacy Policy outlines the practices adopted by TruClimate for the purpose of safeguarding your privacy, whilst instilling confidence in how we manage the personal data/company information you entrust to us. By utilizing the TruClimate website or providing personal information to TruClimate, you consent to the collection, use, disclosure, and/or transfer (including cross-borderHow we collect your personal data/company information transfer) of your personal information in accordance with this Privacy Policy.</p>
                                </div>

                                <div className="entry-title">
                                    <p className="sub-header mb-0">How we collect your personal data/company information</p>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>Personal information is any information that can be used to identify you. TruClimate may collect information about you (such as name, e-mail, phone number, date of birth and postal address), in the following ways:</p>

                                    <ul className="entry-content entry-custom my-0 ml-20">
                                        <li>through our services provided from truclimate.earth and other affiliated TruClimate Sites;</li>
                                        <li>when you contact us to enquire or complain concerning our services;</li>
                                    </ul>
                                    
                                    <p>We will retain all information for a period that refers to the relevant personal data protection laws in force in Indonesia.</p>
                                </div>

                                <div className="entry-title">
                                    <p className="sub-header mb-0">How we share your personal data/company information</p>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>We may share information about you with third parties in certain circumstances including in the following situations:</p>

                                    <ul className="entry-content entry-custom my-0 ml-20">
                                        <li><b>TruClimate Group.</b> We may share information with other companies within the TruClimate group, including our parent company, subsidiaries, and affiliates.</li>
                                        <li><b>Service Providers and Merchants.</b> We may share information with companies or merchants who provide services to us and the users. These services may include but are not limited to, assisting us in fulfilling your service requests, managing our databases, conducting research and analysis on our customers, preparing and distributing communications, or addressing inquiries.</li>
                                        <li><b>Law Enforcement.</b> In cases where we reasonably believe that certain activities are unlawful or could assist in a law enforcement investigation, we may report such activities to the appropriate law enforcement agencies. Furthermore, we retain the right to disclose your information to law enforcement agencies if we determine, at our sole discretion, that you have violated our policies, or if such disclosure is necessary to safeguard the rights, property, or safety of TruClimate or any other individuals.</li>
                                        <li><b>As Permitted by Law.</b> We may disclose your information to others as mandated or authorized by law. This could involve sharing your information with government entities or third parties in response to subpoenas, court orders, or other legal processes. We may also exercise this option when we believe it is necessary to assert our legal rights, protect ourselves against legal claims that have been made, or safeguard against potential legal claims that we, at our sole discretion, determine might arise.</li>
                                    </ul>
                                </div>

                                <div className="entry-title">
                                    <p className="sub-header mb-0">Changes to this Privacy Policy</p>
                                </div>
                                
                                <div className="entry-content entry-custom mt-0">
                                    <p>The personal information you provide will be utilized in accordance with the Privacy Policy applicable at the time of collection. In the event of any changes to our Privacy Policy, we will promptly update our websites to ensure that you remain informed about the information we collect, how we utilize it, and under what circumstances, if any, we disclose it.</p>
                                    <p>For more information on how TruClimate processes your personal data/company information, please refer to our Privacy Policy here.</p>
                                </div>
                                
                                <div className="entry-title">
                                    <h3>PRIVACY POLICY</h3>
                                </div>
                                
                                <div className="entry-content entry-custom mt-0">
                                    <p>This Privacy Policy is part of the TruClimate Website and Services User Agreement (“User Agreement”). The use of the TruClimate Site and any features and/or services available in the TruClimate Application, Sites, sub-domains and/or affiliated services is a form of User consent to the User Agreement and the Privacy Policy. Therefore, the User needs to read this Privacy Policy carefully to ensure that the User fully understands before registering, accessing, and/or using the TruClimate Application and/or TruClimate Site and Services.</p>
	
                                    <ol className="entry-content entry-custom my-0 ml-20">
                                        <li>By using the TruClimate Site and/or the TruClimate Application, the User is deemed to have read this Privacy Policy.</li>
                                        <li>“Personal Data” is all data, information, and/or information in any form that can identify User, including full name, identity card and including all data contained therein, passport and all data, date of birth, type of gender, marital status, height/weight, blood type and rhesus blood, electronic mail address (e-mail), mobile phone number - including but not limited to - IP address, location information, save your location, display your location, your device data, contact number on your smartphone, IMEI number, and including agreeing to provide such data to other applications that TruClimate have made that have been attached and or linked to the TruClimate Application and/or TruClimate Site that the User use on their device, data concerning the information regarding User’s transaction activities on the TruClimate Application and/or TruClimate Site, and other data that is classified as personal data both when the User start using the application and/or as long as the User use the TruClimate Application and/or TruClimate Site.</li>
                                        <li>“Company” means any legal entity whether established in Indonesia or outside of Indonesia which uses the Application and/or Site and any features and/or services provided by TruClimate.</li>
                                        <li>“Company Information” means all and any information, knowledge or data  pertaining to a Company as a user of the TruClimate Site and/or the TruClimate, Application, including, Name, electronic mail address (e-mail), mobile phone number, number of users, address of the Company, Company methods on managing emissions and company information that is not publicly available, as well as data concerning the information regarding the Company’s transaction activities on the TruClimate Application and/or TruClimate Site, and other data that is classified as company information which is collected in the period of use of the Company.</li>
                                        <li>TruClimate collects User's Personal Data/Company Information for the intention to process User transactions, manage and expedite the application process, as well as other purposes as long as permitted by laws and regulations.</li>
                                        <li>TruClimate uses Personal Data/Company Information collected from the User for the following purposes as well as such other purposes as are permitted by applicable data protection laws:</li>

                                        <ol type="A" className="entry-content entry-custom my-0 ml-40">
                                            <li>to identify and register the User as a user on the application/website and to administer, verify, deactivate, or manage your account;</li>
                                            <li>to process all forms of requests, activities, payments, and transactions made by the User through the TruClimate Application and/or TruClimate Site;</li>
                                            <li>to assist the User when communicating with the merchant;</li>
                                            <li>to supervise and/or investigate suspicious transactions;</li>
                                            <li>to offer or provide services from TruClimate’s affiliates or partners;</li>
                                            <li>to enable service providers/merchants to provide the User with such of the services as requested;</li>
                                            <li>to monitor and analyse user activities, behaviour, and demographic data including trends and usage of the various services available on the TruClimate Application;</li>
                                            <li>in certain circumstances, TruClimate may need to use or disclose User’s Personal Data/Company Information for law enforcement purposes in the event of fraud or data theft.</li>
                                        </ol>
                                        
                                        <p className="ml-0">Further description concerning how TruClimate uses your Personal Data/Company Information shall be further explain in the <b>“THE USE OF PERSONAL DATA/COMPANY INFORMATION”</b> section below.</p>
	
                                        <li>TruClimate strives to ensure that the information that User provide to TruClimate is safe and cannot be used by irresponsible parties. For the security of User data, TruClimate strongly recommends that User always update applications and software and does not disclose User passwords to any party.</li>
                                        <li>The User hereby agrees that TruClimate may store their Personal Data/Company Information on a server located in a data centre directly managed by TruClimate. The use of Personal Data/Company Information in connection with the use of the TruClimate Application and/or TruClimate Site will continue to be governed by this Privacy Policy in accordance with the prevailing laws and regulations in the Republic of Indonesia.</li>
                                        <li>This Privacy Policy is governed by the laws of the Republic of Indonesia and the User is required to comply with all applicable regulations in the Republic of Indonesia.</li>
                                    </ol>
                                </div>
                                        
                                <div className="entry-title">
                                    <h3>PERSONAL DATA/COMPANY INFORMATION ACCURACY</h3>
                                </div>
	
                                <ol className="entry-content entry-custom my-0 ml-20">
                                    <li>TruClimate may need the User’s Personal Data/Company Information to process transactions.  The Personal Data/Company Information that the User provides to TruClimate must be as accurate as possible and not misleading. The User shall update and notify TruClimate of any changes to User Personal Data/Company Information. In the event of a data update, TruClimate will update the Personal Data/Company Information no later than 3 x 24 hours from the time TruClimate receives the request for data update and/or correction. By using the TruClimate Application and/or TruClimate Site, the User waives TruClimate from any claims, suits, damages, and/or claims in connection with the failure of transaction processing on the TruClimate Application and/or TruClimate Site caused by any inaccuracy of the Personal Data/Company Information that the User provides to TruClimate.</li>
                                    <li>If the User is below 18 years old, has not married, or is under guardianship, then the User needs the consent of the User’s parents, guardians, or legal guardians to provide Personal Data to TruClimate. When User Personal Data is provided to TruClimate, User hereby represents and warrants that the User parent, legal guardian, or guardian has consented to the processing of User Personal Data and personally accepts and agrees to be bound by this Privacy Policy and is responsible for User actions.</li>
                                    <li>When the User provides Personal Data relating to other people, such as User’s spouse, family members, and/or friends, it also means that the User represents and guarantee that the User has the authority and consent of the owner of the Personal Data to provide their Personal Data to TruClimate for use following the purposes described in this Privacy Policy.</li>
                                    <li>During a Company’s use of both the TruClimate Application or the TruClimate Site, the User representing the Company warrants that it has obtained all of the Company approvals (both internal and external) to provide TruClimate with Company Information for processing in accordance with the provisions set out in this Privacy Policy.</li>
                                </ol>
                                
                                <div className="entry-title">
                                    <h3>USER SELECTION AND TRANSPARENCY</h3>
                                </div>

                                <ol className="entry-content entry-custom my-0 ml-20">
                                    <li>Mobile devices generally have settings so that the TruClimate Application and/or TruClimate Site cannot access certain data without the consent of the User.</li>
                                    <li>The User can access and change information in the form of e-mail address, telephone number, date of birth, gender, address list, payment method, and bank account through the settings feature.</li>
                                </ol>
	
                                <div className="entry-title">
                                    <h3>COLLECTION OF PERSONAL DATA/COMPANY INFORMATION</h3>
                                </div>
	
                                <ol className="entry-content entry-custom my-0 ml-20">
                                    <li>Provision of the User’s Personal Data/Company Information is voluntary. However, if the User does not provide TruClimate with the User’s Personal Data/Company Information, TruClimate cannot provide services or products or process payments from the User.</li>
                                    <li>Collection of User Personal Data/Company Information occurs when the User creates or updates an Account or any other time, if necessary, from time to time. TruClimate will collect the User’s Personal Data/Company Information every time the User access or use the TruClimate Application and/or TruClimate Site.</li>
                                    <li>If the User utilizes a virtual account provided by TruClimate to receive electronic money and/or electronic wallet payment from a payer, whether the User is a service provider or merchant, we may collect certain information related to your utilization including but not limited to service transacted, amount the User collects from every transaction, withdrawal or settlement account details and withdrawal or settlement history.</li>
                                    <li>If the User utilizes and/or when a payment is made through the payment card or account that the User add in the TruClimate Application, TruClimate may collect certain information related to the transaction history (including receiver details), bill details, invoice details, and phone number details.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>THE USE OF PERSONAL DATA/COMPANY INFORMATION</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>The purpose of TruClimate to process, analyse, and/or use User Personal Data/Company Information in accordance with and permitted by the applicable laws and regulations, as follows:</p>
	
                                    <ol type="A" className="entry-content entry-custom my-0 ml-20">
                                        <li>Identify and register the User as a TruClimate Application and/or TruClimate Site user as well as verify, deactivate, or manage the User account, including the Know Your Customer process;</li>
                                        <li>Process and manage TruClimate Application and/or TruClimate Site and related components of TruClimate Application and/or TruClimate Site;</li>
                                        <li>Prevent, detect, investigate, and reduce the occurrence of criminal acts or prohibited, illegal, or fraudulent acts, which may occur in the use of the TruClimate Application and/or TruClimate Site including but not limited to fraud, embezzlement, theft, and money laundering;</li>
                                        <li>Develop, add, and provide services and/or products to meet the User’s needs;</li>
                                        <li>Improve the implementation of research on demographic data of TruClimate Application and/or TruClimate Site;</li>
                                        <li>Send information that TruClimate considers useful to the User after the User has given his consent to TruClimate that the User does not mind being contacted about TruClimate services;</li>
                                        <li>Use the User’s Personal Data/Company Information for internal administrative purposes, such as audit, data analysis, records in database data;</li>
                                        <li>Communicate with the User in connection with all matters regarding the TruClimate Application, TruClimate Site and/or features thereof, while at the same time helping to maintain the safety, security, and continuity of the TruClimate Application and/or TruClimate Site, TruClimate services, and/or features thereof;</li>
                                        <li>Enable TruClimate to provide and process the services the User request or the transactions the User make.</li>
                                        <li>For the development of TruClimate’s services such as calculating users emissions based on accounting data.</li>
                                        <li>To Collect information on new formula of emission factors of users for other users of the TruClimate Application and/or Truclimate Site.</li>
                                        <li>Data Accumulation from multiple users to be used to improve the TruClimate Application and/or TruClimate Site, TruClimate services, and/or features thereof.</li>
                                    </ol>
                                </div>
                                
                                <div className="entry-title">
                                    <h3>LINKS TO THIRD PARTY SITES</h3>
                                </div>
                                
                                <div className="entry-content entry-custom mt-0">
                                    <p>By using the TruClimate Application and/or TruClimate Site and the approval of this Privacy Policy, the User has given TruClimate permission to link to third-party sites. However, TruClimate is not responsible for the acquisition, collection, processing, analysis, use, storage, display, announcement, transmission, dissemination, deletion, and destruction of User Personal Data/Company Information by such third parties. Similarly, suppose the User provides information directly to these sites. In that case, the privacy policies, and terms of service on those sites will apply, and TruClimate is not responsible for these sites’ information processing activities or privacy policies.</p>
                                </div>
	
                                <div className="entry-title">
                                    <h3>SECURITY, STORAGE, AND DELETION OF USER PERSONAL DATA/COMPANY INFORMATION</h3>
                                </div>
	
                                <ol className="entry-content entry-custom my-0 ml-20">
                                    <li>TruClimate protects any Personal Data/Company Information stored in its systems and protects such data from unauthorized access, use, modification, and retrieval, including passwords and OTP codes.</li>
                                    <li>Although TruClimate has used its best efforts to protect the User's Personal Data/Company Information, however, TruClimate cannot guarantee 100% data security, and is at the User's own risk.</li>
                                    <li>Whenever the User uses the TruClimate Application or visits TruClimate Site, certain information may also be collected on an automated basis using cookies. Cookies are small application files stored on the User’s computer or mobile device. We use cookies to track user activity to enhance user interface and experience. Most mobile devices and internet browsers support the use of cookies; but the User may adjust the settings on their mobile device or internet browser to reject several types of certain cookies or certain specific cookies. The mobile device and/or browser would also enable the User to delete at any time whatever cookies have previously been stored. However, doing so may affect the functionalities available on the TruClimate Application or TruClimate Site.</li>
                                    <li>TruClimate will delete or anonymize the User's Personal Data/Company Information that is under TruClimate's control if the Personal Data/Company Information is no longer needed to fulfil the purpose for which it was collected.</li>
                                </ol>
	
                                <div className="entry-title">
                                    <h3>COMPLAINTS REGARDING USER PERSONAL DATA/COMPANY INFORMATION PROTECTION</h3>
                                </div>
	
                                <ol className="entry-content entry-custom my-0 ml-20">
                                    <li>If the User has concerns about TruClimate’s handling or treatment of the User’s Personal Data/Company Information or believes that his or her privacy was violated. In that case, the User may contact TruClimate through the TruClimate service or the TruClimate contact listed in this Privacy Policy by explaining the identity and complaint.</li>
                                    <li>TruClimate will investigate the User complaints and respond to such complaints within a reasonable time after receiving complaints submitted by the User.</li>
                                </ol>
	
                                <div className="entry-title">
                                    <h3>ENTIRE AGREEMENT</h3>
                                </div>
	
                                <div className="entry-content entry-custom mt-0">
                                    <p>Unless expressly agreed in writing by the Parties, this Agreement constitutes the entire agreement between the Parties with respect to the matters handled here and supersedes any previous agreement between the Parties with respect to such matters.</p>
                                </div>
	
                                <div className="entry-title">
                                    <h3>CHANGES TO PRIVACY POLICY</h3>
                                </div>
	
                                <ol className="entry-content entry-custom my-0 ml-20">
                                    <li>TruClimate may at any time change, update, and/or add part or all of the provisions of this Privacy Policy, in accordance with TruClimate’s business going forward, and/or changes to applicable laws and regulations. If such changes or additions are made by TruClimate, TruClimate will notify the User through a notification sent through the TruClimate Application and/or the https://truclimate.earth website, and/or to the User’s email address.</li>
                                    <li>By continuing to use the TruClimate Application and/or TruClimate Site, the User has agreed to the changes, updates, and/or additions that TruClimate has made to this Privacy Policy.</li>
                                    <li>If the User continues to use the TruClimate Application and/or TruClimate Site after reading this Privacy Policy, the User has agreed to this Privacy Policy – including its amendments – and may continue to use the Application.</li>
                                </ol>
	
                                <div className="entry-title">
                                    <h3>TRANSFER OF BUSINESS</h3>
                                </div>
	
                                <div className="entry-content entry-custom mt-0">
                                    <p>In the event of a change of control or a change of ownership of all or part of TruClimate or the Company, including the site, the User’s Personal Data/Company Information may/will be part of the transfer of such assets. TruClimate will further inform the User in the event of a merger, separation, acquisition, consolidation or dissolution.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>PRIORITY ORDER</h3>
                                </div>
	
                                <div className="entry-content entry-custom mt-0">
                                    <p>If the User/Company has agreed to our User Agreement, in the event of any discrepancy between the User Agreement and this Privacy Policy with regard to the use of privacy data, the User Agreement shall prevail.</p>
                                </div>
	
                                <div className="entry-title">
                                    <h3>CONTACT US</h3>
                                </div>
	
                                <div className="entry-content entry-custom mt-0">
                                    <p>If the User has any questions, comments, or claims regarding this privacy policy or wishes to obtain access or correct his/her Personal Data/Company Information, please contact TruClimate customer service via contact@truclimate.earth</p>
                                </div>

                                <div className="entry-meta mt-5">
                                    <ul>
                                        <li>Last Update : 20th Aug 2023</li>
                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Privacy