import { useEffect } from "react";

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <section id="page-title" className="page-title-parallax page-title-dark include-header" style={{backgroundColor:"#ffffff",backgroundImage:"url('assets/images/product/background.png')",backgroundSize:"contain",paddingBottom:"0"}}>

                <div className="container clearfix topmargin-lg bottommargin-lg">
                    <div className="col-lg-6">
                        <h1 className="page-header">Terms & Conditions</h1>
                        <p className="lead"></p>
                    </div>
                </div>

            </section>
            <section id="content">
                <div className="content-wrap">
                    <div className="container">

                        <div className="single-post mb-0">

                            <div className="entry">

                                <div className="entry-title">
                                    <h3>Terms and Conditions of Use</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>These terms of use shall be before using and/or accessing the application or site. The usage of any part of the application or site indicates acceptance of these terms of use.</p>
                                    <p>TruClimate application and/or website of https://truclimate.earth/ is managed by PT Truclimate Dekarbonisasi and its affiliated companies (“we”, “our” or “TruClimate”).</p>
                                    <p>By accessing and/or using any part of application and/or website of  https://truclimate.earth/ the User acknowledges that the User has read and understood and agrees to the terms and conditions herein (“User Agreement”) and other terms related to TruClimate as referred to in this User Agreement.</p>
                                    <p>If the User does not agree to be bound with this User Agreement, the User may not access or use any part of TruClimate. These terms constitute a binding legal agreement between the User as an individual user and TruClimate.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>Definition</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>TruClimate Site refers to the https://truclimate.earth/, as well as any other website owned or shall be owned by TruClimate, including under other domains or sub-domains that shall be developed and operated by TruClimate in the future.</li>
                                    <li>TruClimate Application is an application owned by TruClimate with name of “TruClimate” or any other name to be used by User, which will be available and can be downloaded on the Google Play Store and/or App Store, which will be managed by TruClimate.</li>
                                    <li>Platform refers to TruClimate Application and/or TruClimate Site.</li>
                                    <li>User or Users refer to parties who use Platform services which legally as entity to use TruClimate Site.</li>
                                    <li>Service or Services are the provided services by the Vendor and/or TruClimate on the Platform.</li>
                                    <li>Partner refers to the Project proponent partner selected by TruClimate to be matched to the User in connection with the Services conducted in the Platform, with the capability to collaborate in the co-development of the Project. Such collaboration shall be established under a distinct and definitive agreement to be entered into between the User, TruClimate and/or the Partner.</li>
                                    <li>MOEF Reg refers to the Regulation of the Minister of Environment and Forestry regarding the implementation of carbon trading, including but not limited to  MOEF Regulation No. 21 of 2022 regarding Guidelines for Implementing Carbon Pricing and its amendments (if any).</li>
                                    <li>The National Registry System for Climate Change Control (hereinafter referred to as “SRN-PPI”) is web-based data and information management and provision system on actions and resources for climate change adaptation and mitigation in Indonesia.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>User Account</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>The User hereby declares that the User is a competent entity and is able to bind himself in a legal agreement according to Indonesian law. In this case, the User has also obtained any internal approval (if any) to enter into the Agreement and/or use the Platform (if necessary).</li>
                                    <li>Unless for the provided Service and/or stipulated otherwise in User Agreement or other agreement, TruClimate does not charge a registration fee to User.</li>
                                    <li>User understands that one mobile phone number and one e-mail can only be used to register one TruClimate User account. However, an entity or a company may have several User accounts (main account and additional accounts) by registering with different emails and phone numbers for each User account.</li>
                                    <li>User shall create an account to be able to use the Platform. We will collect and process User's personal data, such as name, e-mail address, telephone number, when the User registers to create an account. User shall provide us with current, complete, and accurate information and agree to provide us with any evidence or identity that we may require. We will only collect and use the said data for administrative purposes and shall not to disclose User's personal data in accordance with our privacy policy, available here.</li>
                                    <li>Only the User can use the User's own account, and the User represents and warrants that the User shall not allow any other party to use the User's identity or account for any reason unless permitted by TruClimate.</li>
                                    <li>User shall maintain the security and confidentiality of User account passwords and any identification we provide to the User. In the event of disclosure of the User's password, in any way that results in unauthorized use of User's account or identity, any act from such usage will still be proceed. Subject to the rights that the User may have legally, the User hereby declares that TruClimate is not responsible for any loss arising from the misuse of the User's account in accordance with this clause.</li>
                                    <li>TruClimate has the irrevocable prerogative right to temporarily block, delete, or deactivate a User's account without giving a reason to the User. Reasons for temporarily blocking, deleting, or deactivating a User's account include but are not limited to:</li>
                                    <ol type="a" className="mb-0 ml-40">
                                        <li>Violating this User Agreement;</li>
                                        <li>Performing criminal activities; and/or</li>
                                        <li>Conduct any suspicious activity.</li>
                                    </ol>
                                    <li>The User hereby declares that TruClimate shall not be responsible for any losses or problems arising from the misuse of the User account caused by the User's negligence, including but not limited to providing the login access of the account provided by TruClimate via messages notification to other parties via the User’s device, lending the account to another party, or the negligence of the User which causing losses or problems in the User's account.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>Scope of Services</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>Through this Platform, we provide an online platform so the Users are able to browse and use any various service types that we provide in the forestry, environmental and other related sectors (“Services”). In this context, Services include:</p>
                                    <ol type="A" className="mb-0 ml-20">
                                        <li>TruCount: any provided services in the Platform for greenhouse gasses (“GHG”) emission accounting software that can be used by the User as a company that will record, monitor, and generate emission reports based on the general acknowledged international and local standards. This Platform will provide expert guidance, emission accounting, and reporting consultations. This Platform also can also provide data-driven solutions based on a feasibility matrix tailored to the User’s needs, and the User can track and manage goals for their decarbonization strategies.</li>
                                        <li>TruCarbon: any services in the Platform for carbon offset marketplace in which the User as a corporation can purchase offsets that will be synced with the GHG emission records on TruCount. The Users can also be matched with the relevant Partners through this service that can work together in the development of the User’s project. The upcoming carbon credit certificates to be issued in the future can be pre-booked or the User can directly buy issued certificates. TruCarbon is entitled to collect fees once the purchasers have successfully purchased the offsets. Small-scale projects could be bundled or grouped together to minimize the development costs and ensure the cost can be balanced by the revenue generated carbon emission reduction outcomes. The Services under this Platform may also include a marketplace for sustainable products and services for emission reduction solutions.</li>
                                    </ol>
                                    <p>We can modify, remove and/or add services on this Platform per our internal policies and changes in applicable laws and regulations.</p>
                                    <p>The arrangement of the Services on the Platform shall be governed by a separate and definitive technical service agreement between the User and TruClimate.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>TruCount</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>As a provider of services to record, monitor, and generate all emissions reports in one Platform; the Platform (TruCount) calculates and analyses emissions based on acknowledged international and local standards to the User.</li>
                                    <li>In relation to the planning and reporting documents, as elaborated, are to be arranged by the business actor internally and will then be validated and verified by accredited validators and verifiers in accordance with the MOEF Reg; TruCount may have a role in the emission trading mechanism by providing the planning and reporting documents, as TruCount will be able to record and calculate the User’s GHG emissions.</li>
                                    <li>The data from the Platform (TruCount), such as the business actor’s emission baseline calculations and periodical analysis of the actual GHG emissions or absorption, will be useful for the User to comply with their planning and reporting requirements.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>TruCarbon</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>As a platform of carbon offset marketplace and carbon credit issuance tool, where the User can purchase GHG emission offset credits, the User shall know that TruCarbon is not the developer or owner of such GHG emission offset projects but rather a platform for transactions of GHG emission offset credits. Thus, any reporting obligation or compliance is not conducted by TruCarbon, but by the developers or owners of such emission reduction activities. It should also be noted that, in providing Services (TruCarbon), TruClimate does not act as a carbon exchange market as stipulated in Law No. 4 of 2023 regarding the Development and Strengthening of the Financial Sector.</li>
                                    <li>TruCarbon may have a role in providing evidence of any carbon credit transfers, as project owners are obliged to report any domestic or international transfer of their carbon credits. TruCarbon’s data regarding such carbon credits transactions may be the supplementary information that will be submitted by the project owner to SRN-PPI as a track record of any transfers of such carbon credits.</li>
                                    <li>TruCarbon is open to any methodologies and offset certification programs, such as Verra, the Clean Development Mechanism (CDM), PlanVivo, The Gold Standard, and even the Indonesian national mechanism for SPE-GRK. However, if TruCarbon intends to issue its own form of carbon credits (not in the form of SPE-GRK) by using its own carbon certification process, such generated credits will be categorized as certificates issued by certification agencies other than the national system. For any GHG emission reduction credits that TruCarbon intends to generate, it will then undergo mutual recognition. Mutual recognition must also be carried out in the case that TruCarbon allows for other forms of certificates not in the form of SPE-GRK to be transferred in its Platform.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>Transactions</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>The User are required to transact through the transaction procedures set by TruClimate.</li>
                                    <li>The User fully understands and agrees that all transactions made by the User, other than the procedures that have been set, are the responsibility of the User.</li>
                                    <li>The User is highly recommended to keep the proof of payment provided for any transactions made in the Platform.</li>
                                    <li>The User understands and agrees that the problem of delays in the payment process and additional costs caused by differences in the bank that the User uses with the TruClimate Authorized Bank Account is the User's personal responsibility.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>Payment Procedures</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 mb-0 ml-20">
                                    <li>All payments are made in Rupiah value and currency.</li>
                                    <li>TruClimate may collect taxes or levies (if necessary or regulated by applicable law and regulation). However, if TruClimate does not determine or instruct, the User (or other parties, if any) will bear their own tax and retribution payments by themselves.</li>
                                    <li>For all forms of transaction, the User shall make payment within the specified time limit. TruClimate reserves the right to cancel the transaction(s) if payment is not made.</li>
                                    <li>Payment methods include but are not limited to bank transfer, and other payment methods available on TruClimate (including any third party payment method that under cooperation with TruClimate). </li>
                                </ol>
                                <div className="entry-content entry-custom mt-0">
                                    <p>For payment by bank transfer, the User must provide a bank transfer receipt as proof of payment for the reservation listed in the TruClimate confirmation email. The User must follow the procedures contained in the confirmation email. </p>
                                </div>

                                <div className="entry-title">
                                    <h3>Services Cost</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>The Services cost contained in the Platform is the price set by TruClimate and/or Vendor. </li>
                                    <li>The User understands and agrees that any problems and/or disputes that occur due to disagreements between the Vendor and the User regarding the price are not the responsibility of TruClimate.</li>
                                    <li>By conducting a transaction through the Platform, the User agrees to pay the total cost to be paid as stated on the payment page.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>Cancellation and Refund</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>The User may only cancel any transactions made on the Platform based on the discretion and policies of TruClimate and/or Vendors.</li>
                                    <li>In connection with a user cancellation that has been made, TruClimate may be able to withhold or take part of the amount paid to reimburse costs that have been incurred in connection with the cancellation. Refund will be carried out based on TruClimate discretion, and the User understands on that policy.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>User Rights</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>TruClimate grants the User a personal, worldwide, royalty-free, non-transferable, non-exclusive, revocable, and non-sublicensable license to access and use the Services. This license is for the sole purpose of enabling the User to use and enjoy the benefits of this Service as intended by TruClimate and permitted by this User Agreement. Therefore, the User agrees not to:</p>
                                </div>
                                <ol type="a" className="entry-content entry-custom mt-0 ml-20">
                                    <li>use the service or any content contained in the Service for any commercial purposes without our express written consent.</li>
                                    <li>copy, modify, transmit, create derivative works, utilize, or reproduce materials, images, trademarks, trade names, copyrighted service marks, or other intellectual property, content, or patent information that can be accessed through the Services without TruClimate's prior written consent.</li>
                                    <li>represent or imply that any statements User makes are endorsed by TruClimate.</li>
                                    <li>use robots, bots (automated programs), spiders (Site searchers), crawlers (web crawlers), scrapers (data collectors), site search/retrieval applications, proxies, or any other manual or automated device, method or process to access, retrieve, index, “data mining”, or reproduce, circumvent the navigation structure or appearance of the Service or its content in any way.</li>
                                    <li>use the Service in any way that may hinder, interfere with, or have a negative impact on the service, servers, or networks connected to the Service.</li>
                                    <li>upload viruses or other malicious code or compromise the security of the Service.</li>
                                    <li>falsify or manipulate identifiers to disguise the origin of information transmitted to or through the Service.</li>
                                    <li>“plagiarize” or “copy” any part of the Service without Truclimate's prior written permission.</li>
                                    <li>check, scan, or test for vulnerabilities of our Service or any system or network.</li>
                                    <li>encourage or support any activity that violates this User Agreement.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>Intellectual Property Rights</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>We are the licensee rights to the 'TRUCLIMATE' name, icon and logo, which are copyrights and trademarks protected by the laws of the Republic of Indonesia. The User may not use, modify, or install the name, icon, logo, or brand without our written consent.</li>
                                    <li>We may take legal action against any violations committed by the User related to intellectual property rights related to the Application.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>Other Provisions</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>If the User uses the features available in the Platform, the User hereby declares that he understands and agrees to all the terms and conditions that are specifically regulated.</li>
                                    <li>Everything that has not been and/or is not regulated in the User Agreement will fully refer to the user agreement in general.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>USER REPRESENTATION AND WARRANTIES</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>User represents and warrants to Truclimate that it is (i) legally competent to enter into the Agreement and use the Platform; (ii) has obtained approval (both internal and external) to enter into the Agreement and use the Platform (if any); and (iii) is not prohibited or contradicted by other agreements between the User and other parties.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>DISCLAIMER OF WARRANTIES AND LIMITATIONS OF LIABILITY</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>TruClimate always strives to keep the Platform’s services safe, convenient, and functioning properly, but we cannot guarantee continuous operation or perfect access to our services. Information and data in the Platform may not occur in real-time.</li>
                                    <li>The User agrees that the User uses the TruClimate service at the User's risk and the TruClimate service is provided to the User on an “as is” and “as available” basis.</li>
                                    <li>To the extent permitted by applicable law, TruClimate shall not be responsible, and the User agrees not to claim TruClimate for any damage or loss resulting directly or indirectly from:</li>
                                    <ol type="a" className="ml-40">
                                        <li>User's use or inability to use Services.</li>
                                        <li>Prices, shipping, or other instructions available in the Services.</li>
                                        <li>Delays or interruptions in Services.</li>
                                        <li>Negligence caused by the User.</li>
                                        <li>Disputes between User.</li>
                                        <li>Infringement of Intellectual Property Rights.</li>
                                        <li>Defamation.</li>
                                        <li>Losses due to payment outside the method specified by TruClimate.</li>
                                        <li>Virus or other malicious software obtained by accessing or connecting to Services.</li>
                                        <li>Any interruptions, bugs, errors, or inaccuracies in the Services.</li>
                                        <li>There are hacking actions carried out by third parties to User accounts.</li>
                                    </ol>
                                </ol>

                                <div className="entry-title">
                                    <h3>COMPENSATION</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>The User shall indemnify TruClimate and hold TruClimate against any claims and demands, including reasonable legal fees, made by third parties arising if the User violates the provision in this User Agreement and improper use of TruClimate's services.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>UPDATE</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>This User Agreement may be changed and/or updated from time to time without prior notice. Truclimate recommends that the User read carefully and check this User Agreement page from time to time for any changes. By continuing to access the Truclimate service, the User is deemed to have agreed to changes in the User Agreement.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>CHOICE OF LAW AND CHOICE OF FORUM</h3>
                                </div>
                                
                                <div className="entry-content entry-custom mt-0">
                                    <p>This User Agreement is governed and made under the laws of the Republic of Indonesia. The user agrees that if a dispute arises over this User Agreement or as a result of our services, the dispute resolution is carried out by deliberation and consensus. If it cannot be resolved, the dispute can be resolved through the Badan Arbitrase Nasional Indonesia (BANI) address in Wahana Graha Lt. 1&2, Jalan Mampang Prapatan No. 2, Duren Tiga, Pancoran, RT.4/RW.1, Duren Tiga, Kec. Pancoran, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12760; in accordance with applicable provisions regarding dispute resolution through arbitration in Indonesia.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>FORCE MAJEURE</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>Truclimate is not responsible for any violation of this User Agreement in relation to unexpected system damage. Force majeure includes but is not limited to natural disasters (floods, earthquakes, fires), terrorism, pandemic, epidemic, embargoes, changes in laws and regulations, and cyber attacks.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>ENTIRE AGREEMENT</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>This User Agreement constitutes the entire agreement between the User and TruClimate with respect to the subject matter of this User Agreement. All prior agreements or arrangements, written or oral, between the User and TruClimate relating to the subject matter are hereby superseded by this User Agreement.</p>
                                </div>

                                <div className="entry-title">
                                    <h3>GENERAL</h3>
                                </div>

                                <ol className="entry-content entry-custom mt-0 ml-20">
                                    <li>This User Agreement will govern the entire agreement and understanding between the User and TruClimate on the subject matter thereof and will supersede all prior agreements, whether written or oral, between the User and TruClimate regarding the subject matter thereof. If necessary, TruClimate and Users (as well as other party, if relevant) may sign a separate agreement, as an implementation of the Service(s) providence to the Users. </li>
                                    <li>Truclimate, and Users (as well as other party, if any) agree to waive the provisions of Articles 1266 and 1267 of the Civil Code to the extent that these Articles require court approval for the termination of this User Agreement.</li>
                                    <li>For the avoidance of doubt, Truclimate does not manage or control the Vendor.</li>
                                    <li>This User Agreement is made in Indonesian and English and is in accordance with the statutory provisions regarding the use of the Indonesian language. In the event that there is a difference in translation, the Indonesian version will prevail, and the English version must be adapted to the Indonesian version. Furthermore, the Users also agree that they will not file any claims or lawsuits against Truclimate regarding the use of language in this User Agreement.	</li>
                                    <li>If any provision is determined to be unenforceable or invalid, by a court of competent jurisdiction, that provision will remain in force to the fullest extent permitted by applicable law and such provision shall not affect the validity and enforceability of any other remaining provisions. Disconnected provisions should be replaced with provisions that are as close to the original wording and intent as possible.</li>
                                    <li>The failure of either Party to enforce any provision of these Terms at any time shall not be construed as a waiver of the respective Party's right to enforce its breach of such provision or any other provision of these Terms or as a waiver of its continued breach, following or following any other terms or conditions of this User Agreement.</li>
                                    <li>You may not assign or assign your rights or obligations under these Terms, without our prior written consent.</li>
                                    <li>This User Agreement and Privacy Policy (available here) are an integral part that must be mutually agreed upon by the User before using the Platform.</li>
                                </ol>

                                <div className="entry-title">
                                    <h3>FAQs</h3>
                                </div>

                                <div className="entry-content entry-custom mt-0">
                                    <p>If there are any questions, please refer to the FAQs.</p>
                                    <p>Or contact us through our email contact@truclimate.earth.</p>
                                </div>

                                <div className="entry-meta mt-5">
                                    <ul>
                                        <li>Last Update : 20th Aug 2023</li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Terms