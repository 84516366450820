import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import TruCarbon from "./components/TruCarbon";
import TruCount from "./components/TruCount";
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/privacy-policy" element={
          <div id="wrapper" className="clearfix">
            <Header />
            <Privacy />
            <Footer />
          </div>
        } />
        <Route path="/terms-conditions" element={
          <div id="wrapper" className="clearfix">
            <Header />
            <Terms />
            <Footer />
          </div>
        } />
        <Route path="/" element={
          <div id="wrapper" className="clearfix">
            <Header />
            <Home />
            <Footer />
          </div>
        } />
        <Route path="/trucarbon" element={
          <div id="wrapper" className="clearfix">
            <Header />
            <TruCarbon />
            <Footer />
          </div>
        } />
        <Route path="/trucount" element={
          <div id="wrapper" className="clearfix">
            <Header />
            <TruCount />
            <Footer />
          </div>
        } />
        <Route path="/contact" element={
          <div id="wrapper" className="clearfix">
            <Header />
            <Contact />
            <Footer />
          </div>
        } />
        <Route path='/' element={<Navigate to={'/'} />} />
        <Route path='*' element={<Navigate to={'/'} />} />
      </Routes>
    </Router>
  );
}

export default App;
