import { useEffect } from "react";
import { Link } from "react-router-dom";
import trucarbon from "../assets/home/trucarbon.png";
import offset from "../assets/product/offset.png";
//import marketplace from "../assets/product/marketplace.png";
import funding from "../assets/product/funding.png";
import check from "../assets/home/check.png";

const TruCarbon = () => {
    useEffect(() => {
        document.querySelector("#tab-button-1").classList.add('ui-tabs-active');
        //document.querySelector("#tab-button-2").classList.remove('ui-tabs-active');
        document.querySelector("#tab-button-3").classList.remove('ui-tabs-active');
        document.querySelector("#snav-content1").style.setProperty('display', 'block');
        document.querySelector("#snav-content2").style.setProperty('display', 'none');
        document.querySelector("#snav-content3").style.setProperty('display', 'none');
        window.scrollTo(0, 0);
    });

    const changeTab = async(id) => {
        if(id === 1){
            document.querySelector("#tab-button-1").classList.add('ui-tabs-active');
            //document.querySelector("#tab-button-2").classList.remove('ui-tabs-active');
            document.querySelector("#tab-button-3").classList.remove('ui-tabs-active');
            document.querySelector("#snav-content1").style.setProperty('display', 'block');
            document.querySelector("#snav-content2").style.setProperty('display', 'none');
            document.querySelector("#snav-content3").style.setProperty('display', 'none');
        }else if(id === 2){
            //document.querySelector("#tab-button-2").classList.add('ui-tabs-active');
            document.querySelector("#tab-button-1").classList.remove('ui-tabs-active');
            document.querySelector("#tab-button-3").classList.remove('ui-tabs-active');
            document.querySelector("#snav-content2").style.setProperty('display', 'block');
            document.querySelector("#snav-content1").style.setProperty('display', 'none');
            document.querySelector("#snav-content3").style.setProperty('display', 'none');
        }else if(id === 3){
            document.querySelector("#tab-button-3").classList.add('ui-tabs-active');
            document.querySelector("#tab-button-1").classList.remove('ui-tabs-active');
            //document.querySelector("#tab-button-2").classList.remove('ui-tabs-active');
            document.querySelector("#snav-content3").style.setProperty('display', 'block');
            document.querySelector("#snav-content1").style.setProperty('display', 'none');
            document.querySelector("#snav-content2").style.setProperty('display', 'none');
        }
    }

    return (
        <div>
            <section id="page-title" className="page-title-parallax page-title-dark include-header" style={{backgroundColor:"#ffffff",backgroundImage:"url('assets/images/product/background.png')",backgroundSize:"contain",paddingBottom:"0"}}>

                <div className="container clearfix topmargin-lg bottommargin-lg">
                    <div className="col-lg-6">
                        <img src={trucarbon} className="product-header" alt="TruCarbon" />
                        
                        <p className="lead">Carbon Offsets Solutions for all</p>
                    </div>
                </div>

                <div className="container clearfix">
                    <div className="row mb-0">
                        <div className="col-sm-12 col-lg-6">
                            <div className="feature-box fbox-effect">
                                <div className="fbox-icon mb-4">
                                    <a href="#offset"><img src={offset} alt="offset" /></a>
                                </div>
                                <div className="fbox-content">
                                    <h3>Offset Issuance Tools</h3>
                                    <p>For Carbon Project Developers</p>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-sm-12 col-lg-4">
                            <div className="feature-box fbox-effect">
                                <div className="fbox-icon mb-4">
                                    <a href="#marketplace"><img src={marketplace} alt="marketplace" /></a>
                                </div>
                                <div className="fbox-content">
                                    <h3>Marketplace</h3>
                                    <p>Buy & Sell Quality Offsets</p>
                                </div>
                            </div>
                        </div>*/}

                        <div className="col-sm-12 col-lg-6">
                            <div className="feature-box fbox-effect">
                                <div className="fbox-icon mb-4">
                                    <a href="#funding"><img src={funding} alt="funding" /></a>
                                </div>
                                <div className="fbox-content">
                                    <h3>Project Funding</h3>
                                    <p>Facilitating carbon project development</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">

                        <div id="section-specs" className="heading-block text-center page-section">
                            <h2>WHAT WE OFFER</h2>
                        </div>

                        <div id="side-navigation" className="row col-mb-50">

                            <div className="col-md-4">
                                <ul className="sidenav">
                                    <li id="tab-button-1" onClick={() => changeTab(1)}><a href="#content1">Offset Issuance Tools</a></li>
                                    {/*<li id="tab-button-2" onClick={() => changeTab(2)}><a href="#content2">Marketplace</a></li>*/}
                                    <li id="tab-button-3" onClick={() => changeTab(3)}><a href="#content3">Project Funding</a></li>
                                </ul>
                            </div>

                            <div className="col-md-8">
                                <div id="snav-content1">
                                    <h3>Offset Issuance Tools</h3>

                                    <h4>Build to empower Green Project Developers</h4>

                                    <p className="product-detail">At TruCarbon, we believe that sustainable initiatives have the opportunity to be profitable ventures to provide incentives and reward Green Project Developers for making positive impacts on the environment<br/><br/>
                                    That's why we strive to remove the barriers that prevent project developers from issuing carbon offsets, by providing accessible and affordable tools, so more green and sustainable projects can be developed to help the world achieve it's sustainability and climate ambitions<br/><br/>
                                    Our tools and services are designed to increase transparency on offset qualities by providing proprietary assessments on Additionality, Permanence, Safeguards and SDGs relevant to the carbon offsets</p>

                                    <h4>Features for Project Developers :</h4>

                                    <ul className="iconlist iconlist-color product-detail mb-0">
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Quick eligibility check by answering a simple survey to know your carbon project potential in terms of feasibility and revenue</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Enabling you to find the right Experts to assist you in carbon project development, carbon offset issuance process, up to the sales of carbon offsets</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Step by step guidance with informative insights to make your issuance process a breeze</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Support carbon offsets from various carbon mechanisms and programs<br/>(Verra, Gold Standard, CDM, VIVO, ICER, I-REC, TIGR, and many more)</p></li>
                                    </ul>

                                    {/*<Link to={'/contact'} className="button button-circle button-small">Sign Up</Link>*/}
                                    <Link to={'/contact'} className="button button-circle button-small button-border">Take Eligibility Check Survey</Link>
                                </div>

                                <div id="snav-content2">
                                    <h3>Marketplace</h3>

                                    <h4>Accessible Carbon Market for Everyone</h4>

                                    <p className="product-detail">Our TruCarbon marketplace is dedicated to making the process of buying and selling carbon offsets simple and accessible for everyone.</p>

                                    <h4>Features for Marketplace :</h4>

                                    <ul className="iconlist iconlist-color product-detail mb-0">
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Allows you to <b>browse, book, and purchase</b> carbon offsets and RECs from various AFOLU and renewable energy projects</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0"><b>Transparent assessment on carbon offset qualities.</b> At TruCarbon you can purchase carbon offset credits with confidence, knowing that they have been independently verified to meet the highest standards of quality</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">We offer a <b>wide range of offset options</b> including renewable energy projects and reforestation, that also support various Sustainable Development Goals within their programs</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0"><b>Advance booking for validated Offsets & RECs.</b> Get the latest listing update through TruCarbon so your companies can get guaranteed first move on quality Offsets</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">For TruCount users, purchased offsets on TruCarbon will synchronized to your net GHG emissions records automatically</p></li>
                                    </ul>

                                    {/*<Link to={'/contact'} className="button button-circle button-small">Sign Up</Link>*/}
                                </div>

                                <div id="snav-content3">
                                    <h3>Project Funding</h3>

                                    <p className="product-detail">Turn your sustainability project into a reality with TruCount</p>

                                    <h4>Carbon Project Financing :</h4>

                                    <ul className="iconlist iconlist-color product-detail mb-0">
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Are you in need of investment to get your Carbon Project off the ground? Or, do you have an idea for a Carbon Project but don't know where to start?</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">We understand that creating a successful carbon offset project from scratch is a daunting task, requiring vast resources and expert advice</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">We are currently developing our Project Funding Platform to make it easier for Carbon Projects Developers to gain access to capital and financial support for their projects</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">In the meantime, leave us details about your projects so we can help connect your organization with our network of Green Investors</p></li>
                                    </ul>

                                    <Link to={'/contact'} className="button button-circle button-small">Submit Request</Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default TruCarbon