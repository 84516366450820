import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const Header = () => {
	const toggleNavigation = async() => {
		if(!document.querySelector("#menu-container").style.display){
			document.querySelector("#menu-container").style.setProperty('display', 'block');
			document.querySelector("#body-page").classList.add('primary-menu-open');
		}else{
			document.querySelector("#menu-container").style.removeProperty('display');
			document.querySelector("#body-page").classList.remove('primary-menu-open');
		}
	}

  	return (
		<header id="header" className="full-header">
			<div id="header-wrap">
				<div className="container">
					<div className="header-row">

						<div id="logo">
							<Link to={"/"} className="standard-logo" data-dark-logo="images/logo-dark.png"><img src={logo} alt="truclimate" style={{width:"150px", height:"auto"}} /></Link>
							<Link to={"/"} className="retina-logo" data-dark-logo="images/logo-dark@2x.png"><img src={logo} alt="truclimate" style={{width:"150px", height:"auto"}} /></Link>
						</div>

						<div className="header-misc ms-auto ms-xl-0">
							{/* eslint-disable-next-line */}
							<a href="https://staging-account.truclimate.earth" target="_blank" className="button button-border border-contrast-900 text-contrast-900 h-text-contrast-100 button-shadow button-shadow-contrast rounded-1 px-3 px-lg-4 me-3 me-xl-0"><i className="fa-solid fa-shopping-cart d-block d-md-none me-1"></i><span className="d-md-block">Login</span></a>
						</div>

						<div id="primary-menu-trigger" onClick={() => toggleNavigation()}>
							<svg className="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
						</div>

						<nav className="primary-menu">

							<ul id="menu-container" className="menu-container" style={{border:"0px"}}>
								<li className="menu-item">
									<Link to={"/"} className="menu-link"><div>Home</div></Link>
								</li>
                				<li className="menu-item">
									<a className="menu-link" href="#product"><div>Our Products</div></a>
									<ul className="sub-menu-container">
										<li className="menu-item mega-menu-title">
											<Link to={'/trucount'} className="menu-link"><div>TruCount</div></Link>
										</li>
										<li className="menu-item mega-menu-title">
											<Link to={'/trucarbon'} className="menu-link"><div>TruCarbon</div></Link>
										</li>
									</ul>
								</li>
                				{/*<li className="menu-item">
									<a className="menu-link" href="#"><div>Our Story</div></a>
									<ul className="sub-menu-container">
										<li className="menu-item">
											<Link to={'/about'} className="menu-link"><div>About Us</div></Link>
										</li>
										<li className="menu-item">
											<a className="menu-link" href="#"><div>Our Project</div></a>
										</li>
										<li className="menu-item">
											<Link to={'/faq'} className="menu-link"><div>FAQ</div></Link>
										</li>
									</ul>
								</li>
								<li className="menu-item">
									<a className="menu-link" href="#"><div>Career</div></a>
								</li>
								<li className="menu-item">
									<a className="menu-link" href="#"><div>Blog</div></a>
  								</li>*/}
								<li className="menu-item">
									<Link to={'/contact'} className="menu-link"><div>Contact Us</div></Link>
								</li>
							</ul>

						</nav>

					</div>
				</div>
			</div>
			<div className="header-wrap-clone"></div>
		</header>
  	)
}

export default Header